@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Silkscreen&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik+Maze&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alumni+Sans+Pinstripe&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cairo&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Michroma&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sora&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Baloo+2:wght@600&display=swap');

.titleFont {
    font-family: 'Roboto', sans-serif;
  }
  
  .subFont {
    font-family: 'Raleway', sans-serif;
  }
  
  .nav {
    color: white;
    font-family: 'Roboto', sans-serif;
  }  

  .navButton {
    color: black;
    font-family: 'Roboto', sans-serif;
  }  

  .text {
    font-family: 'Cairo', sans-serif;
  }


  .funkFont {
    font-family: 'Alumni Sans Pinstripe', sans-serif;
  }

  .reg {
    font-family: 'Michroma', sans-serif;
  }

  .about {
    font-family: 'Nunito', sans-serif;
  }

  .Josefin {
    font-family: 'Josefin Sans', sans-serif;
    color: white;
  }

  .sora {
    font-family: 'Sora', sans-serif;
  }

  .Baloo2 {
    font-family: 'Baloo 2', cursive;
    
  }

  .mont {
    font-family: 'Montserrat', sans-serif;
    color: white;
  }

