.contact-title-image{
    width: 150px;
    height: 150px;

    opacity: .75;
    margin-left: 35px;
    margin-right: 35px;
}

  
@media screen and (max-width: 1200px) {
    .contact-title-image {
        width: 125px;
        height: 125px; 
        
        margin-left: 25px;
        margin-right: 25px;
    }
}
  
@media screen and (max-width: 1023px) {
    .contact-title-image {
        width: 115px;
        height: 115px;
        
        margin-left: 15px;
        margin-right: 15px;
    }
}

@media screen and (max-width: 820px) {
    .contact-title-image {
        display: none;
    }
}
  




/*===========================================*/


.form-header {
    display: flex;
    justify-content: center;
   
    width: 600px;
    margin-bottom: 25px;

    font-family: "Baloo 2";
    font-size: 27px;
    font-weight: bold;
    text-align: center;
    letter-spacing: 2px;

    color:#fff

}

.form-input {
    display: flex;
    justify-content: center;

    width: 600px;
    margin: 1rem auto; 
    margin-bottom: 25px;
    border-radius: .5rem;


    background-color: #2d2d2d;
    color: #fff; 
}

.form-submit {
    display: flex;
    justify-content: center;
    justify-self: center;
    margin: auto;
    line-height: 4.5rem;

    width: 600px;
    height: 75px;
    border-radius: .5rem;
    transition: ease-out 0.5s;
    outline: none;
    border: none;
    cursor: pointer;
  
  
    font-weight: bold;
    font-size: 25px;
    font-family: 'Baloo 2';
    letter-spacing: 2px;
    text-align: center;
  
    background: linear-gradient(-45deg, #282525, #1a191a, #2d2d2d, #2c2b2b);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
  
    color: #fff;
  
}
  
.form-submit:hover {
    background-color: #1a7851;
    box-shadow: inset 650px 0 0 0 #1a7851;
}

/*===========================================*/

@media screen and (min-width: 1200px) {
    .form-input, .form-submit, .form-header {
        width: 650px;
    }
}


@media screen and (max-width: 950px) {
    .form-input, .form-submit, .form-header {
        width: 575px;
    }
}

@media screen and (max-width: 820px) {
    .form-input, .form-submit, .form-header {
        width: 500px;
    }

    .form-submit:hover {
        box-shadow: none;
    }
}

@media screen and (max-width: 750px) {
    .form-input, .form-submit, .form-header {
        width: 475px;
    }
}


@media screen and (max-width: 725px) {
    .form-input, .form-submit, .form-header {
        width: 450px;
    }
}

@media screen and (max-width: 660px) {
    .form-input, .form-submit, .form-header {
        width: 425px;
    }
}

@media screen and (max-width: 600px) {
    .form-input, .form-submit, .form-header {
        width: 400px;
    }

    .form-header {
        font-size: 20px;
    }
}

@media screen and (max-width: 575px) {
    .form-input, .form-submit, .form-header {
        width: 375px;
    }
}

@media screen and (max-width: 500px) {
    .form-input, .form-submit, .form-header {
        width: 325px;
    }
}

@media screen and (max-width: 450px) {
    .form-input, .form-submit, .form-header {
        width: 300px;
    }
}

@media screen and (max-width: 375px) {
    .form-input, .form-submit, .form-header {
        width: 275px;
    }
}

@media screen and (max-width: 350px) {
    .form-input, .form-submit, .form-header {
        width: 225px;
    }
}

@media screen and (max-width: 300px) {
    .form-input, .form-submit, .form-header {
        width: 175px;
    }
}






