/*Monoton*/
@import url('https://fonts.googleapis.com/css2?family=Monoton&display=swap');
.countdown-timer {
    color: white;
    font-family: 'Baloo2';
    font-weight: 700;
    font-size: 3.5em;
    display: flex;
    justify-content: center;
    align-items: center;
}

.countdown-timer span {
    margin-left: 5px;
    margin-right: 5px;
}

.two-numbers {
    width: 2ch;
}

.fontWhite {
    color: white;
}

.animate-charcter {
   text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #fff,
    /* #2d2d2d */
    /* 4e32a8 */
    #4e32a8 90%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 8s linear infinite;
  display: inline-block;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}