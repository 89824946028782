@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Signika:wght@600&display=swap');/* exo regular */
@import url('https://fonts.googleapis.com/css2?family=Exo:wght@100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;800;900&display=swap');
/* baloo2 */
@import url('https://fonts.googleapis.com/css2?family=Baloo+2&family=Roboto:wght@100&display=swap');
/* roboto */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Antic+Slab&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Monoton&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans&display=swap');


.colorWhite {
    color: white;
}

.bgBlackImg {
    background: #fff url(https://images.pexels.com/photos/6044197/pexels-photo-6044197.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2) center center/cover no-repeat;

}.bgWhiteGradient {
    background: #fff url(https://wallpaperaccess.com/full/1586344.jpg) center center/cover no-repeat;
}

@layer base {
    body {
        @apply font-[Raleway];
    }
    li {
        @apply px-4;
        @apply cursor-pointer
    }
}

.titlePage {
    
    border-bottom: 10px solid rgb(255, 255, 255);
    width:100%; 
    align-items: center;
    justify-content: center;
}

.content-div {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 250px;
}

.content-div:hover {
    background-image: linear-gradient(
        to right,
        rgba(112, 157, 255, 0.8),
        hsla(242, 74%, 61%, 0.8)   
    )!important;
}
.roadmapClass {
    background-color: #FFFFFF;

}

.fontColorBlack {
    color: black;

}
.fontColorWhite {
    color: white;

}
.backgroundBlack {
    background-color: black;
}


.backgroundDarkGradient {
    background-color: #000000;
    background-image: linear-gradient(147deg, #000000 0%, #434343 74%);
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.faq_style {
    display: flex;
    justify-content: center;
    align-content: center;
    padding: 15px;
}

header {
    display: flex;
    justify-content: center;
    align-content: center;
    padding: 5px;
    background: #121113; 
    border: 5px solid #2d2d2d;
}

header h1 {
    color: white;
    font-size: 35px;
    font-weight: 700;
    font-family: "Baloo 2";
    font-weight: bold;
    letter-spacing: 5px;
    text-transform: uppercase;
}

.line {
  display: flex;
  justify-content: center;
  margin-top: 10px;

  width: 100%;
  height: 5px;
  border-radius: 2rem;

  background-color: #2d2d2d;
  opacity: .5;
}
  

.faqs {
    width: 100%;
    max-width: 768px;
    margin: 0 auto;
    padding: 15px;
    font-size: 20px;
}

body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helveltica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #EEE;
}



.fontColorWhite {
    color: white;
}

.backgroundGrey {
    background-color: #121113;
}



.faqs .faq {
    margin: 15px;
    margin-top: 45px;
    border-radius: 2rem;
    padding: 25px;

    background-color: #2d2d2d;
    box-shadow: 0px 0px 25px -10px #3a0ca3;

    color: #FFFFFF;
    font-family: "Baloo 2"; 
    
}

.faqs .faq .faq-question {
    position: relative;
    font-size: 25px;
    letter-spacing: 2px; 
    padding-right: 80px;

    transition: all 0.4s ease;
}



.faqs .faq .faq-question::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;


    background-image: url('./components/assets/down-arrow-svgrepo-com (1).svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-reapeat;

    transition: all 0.4s ease;
}

.faqs .faq .faq-answer {
    opacity: 0;
    max-height: 0;
    overflow-y: hidden;
    transition: all 0.6s ease-out;

}

.faqs .faq.open .faq-question {
    margin-bottom: 25px;
}

.faqs .faq.open .faq-question::after {
    transform: translateY(-50%) rotate(180deg);
}

.faqs .faq.open .faq-answer {
    max-height: 1000px;
    opacity: 1;
}


.css-selector {
    background: linear-gradient(-45deg, #37829f, #ae71ae, #2f57cf, #cf63c4);
    background-size: 800% 800%;
    animation: AnimationName 36s ease infinite;
    width: 100%;
    height: 100%;
    
}

@keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}



.button {
    
    background: #fff5f6;
    /* background: -webkit-linear-gradient(top, #9c3e5e, #d665ab);
    background: -moz-linear-gradient(top, #9c3e5e, #d665ab);
    background: -ms-linear-gradient(top, #9c3e5e, #d665ab);
    background: -o-linear-gradient(top, #9c3e5e, #d665ab); */
    padding: 0px 6px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    -webkit-box-shadow: rgba(0,0,0,1) 0 1px 0;
    -moz-box-shadow: rgba(0,0,0,1) 0 1px 0;
    box-shadow: rgba(0,0,0,1) 0 1px 0;
    text-shadow: rgba(0,0,0,.4) 0 px 0;
    color: #000000;
    font-size: 17px;
    font-weight: 500;

    
    }

    @media only screen and (max-width: 600px) {

      } 
    
      /* Small devices (portrait tablets and large phones, 600px and up) */
      @media only screen and (min-width: 600px) {

      } 
    
      /* Medium devices (landscape tablets, 768px and up) */
      @media only screen and (min-width: 800px) {

        
      }
 .button:hover {
    border-top-color: #ededed;
    background: #ededed;
    color: #000000;
    }
 .button:active {
    border-top-color: #f70a29;
    background: #a9b8c4;
    }

.connectedText {
    background: #00FF00;
    background: linear-gradient(to left, #00FF00 0%, #00FFFF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    
}    

.banner {
    margin-top: 5%;
}

.headerFont {
    font-size: 100px;
}

.rdHead {
    font-family: 'Baloo 2', cursive;
}

.insta {
    background: #f09433; 
    background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%); 
    background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
    background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
}

.opensea {
    background-color: #045de9;
    background-image: linear-gradient(315deg, #045de9 0%, #09c6f9 74%);
}

.discord {
    background-color: #7f5a83;
    background-image: linear-gradient(315deg, #7f5a83 0%, #0d324d 74%);
}

.twitter {
    background-color: #6a93cb;
    background-image: linear-gradient(315deg, #6a93cb 0%, #a4bfef 74%);
}

.etherscan {
    background-color: #31363c;
    background-image: linear-gradient(315deg, #31363c 0%, #314a76 74%);
}

.disUnder {
    position: relative; 
    z-index: -10; 
}

.disMiddle {
    position: relative; 
    z-index: 50; 
}

.disOver {
    z-index: 100; 
}

/*========================================================================================== */

.grid-container {
    display: grid; 
    grid-template-columns: 200px 250px; 
    grid-auto-rows: minmax(150px, auto); 
    grid-row-gap: 20px; 
    grid-column-gap: 20px; 
    grid-template-areas: 
        "header header"
        "sidebar content"
        "sidebar content"

}

.grid-item-1 {
    grid-column-start: 1 / -1;
    background-color: blueviolet
    
}

.grid-item-2 {
    grid-row-start: 2; 
    grid-row: 4; 
}

.grid-item-3 {
    grid-row-start: 2;
    grid-row-end: 4; 
}




 @media only screen and (max-width: 600px) {
    /* CSS */
.button-85 {
    padding: 1em 2em;
    border: none;
    outline: none;
    color: rgb(24, 24, 24);
    background: rgb(255, 255, 255);
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }
  } 

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
/* CSS */
.button-85 {
    padding: 1em 4em;
    border: none;
    outline: none;
    color: rgb(24, 24, 24);
    background: rgb(255, 255, 255);
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }
  } 
  

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 800px) {
/* CSS */
.button-85 {
    padding: 1em 3.5em;
    border: none;
    outline: none;
    color: rgb(24, 24, 24);
    background: rgb(255, 255, 255);
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }
}

@media only screen and (min-width: 1000px) {
/* CSS */
.button-85 {
    padding: 1em 5.9em;
    border: none;
    outline: none;
    color: rgb(24, 24, 24);
    background: rgb(255, 255, 255);
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    }
}



.button-85:before {
  content: "";
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  -webkit-filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing-button-85 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

@keyframes glowing-button-85 {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.button-85:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgb(255, 250, 250);
  left: 0;
  top: 0;
  border-radius: 10px;
}



.titleNumbers {

    
    /* border: .1px solid white; */

    color: rgb(231, 222, 222);

    font-family: 'Monoton', cursive;
    font-size: 35px;

    /* display: flex; */
    justify-content: center;
    align-items: center;

    font-weight: 100;
}


.marginTop {
    margin-top: 10%;
}


.mintBtn {
    width: 40%;
    height: 50px;
    border-radius: 2rem;
    transition: ease-out 0.5s;
    outline: none;
    border: none;
    cursor: pointer;
    margin-bottom: 5px;
    margin-top: 5px;

    font-weight: bold;
    font-size: 20px;
    font-family: 'Baloo 2';
    letter-spacing: 1px;
    text-align: center;

    background: linear-gradient(-45deg, #37829f, #ae71ae, #2f57cf, #cf63c4);
    background-size: 400% 400%;
      animation: gradient 15s ease infinite;

    color: #fff;

}

.mintBtn:hover {
background-color: #2d2d2d;
box-shadow: inset 400px 0 0 0 #2d2d2d;
}

.mintBackground {
    background-image: url("./components/assets/Lightning_Bursts_1_2286_2K.gif");
    background-size: 100vw 150vh ;
}
