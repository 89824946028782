

.help-title-image{
    width: 150px;
    height: 150px;

    opacity: .75;
}

  
@media screen and (max-width: 1300px) {
    .help-title-image {
        width: 125px;
        height: 125px;  
    }
}
  
@media screen and (max-width: 1023px) {
    .help-title-image {
        width: 100px;
        height: 100px;  
    }
}

@media screen and (max-width: 850px) {
    .help-title-image {
        width: 75px;
        height: 75px;  
    }
}
  
@media screen and (max-width: 820px) {
    .help-title-image {
        display: none; 
    }
}

/*==============================================================*/

.subheaderFlex{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    height: auto;
    margin-top: 75px;
    gap: 75px;
}


.left-subheader {
  width: fit-content;
  padding: 25px;
  max-width: 550px;

  font-size: 35px;
  font-weight: bold;
  font-family: 'Baloo 2';
  text-align: center;
  letter-spacing: 2px;
  color: #fff;
  
  border-radius: 2rem;
  box-shadow: 0 0 5px 10px rgba(50, 50, 50, 0.25);
  background: linear-gradient(-45deg, #282525, #1a191a, #2d2d2d, #2c2b2b);
  background-size: 400% 400%;
  
  animation: gradient 15s ease infinite;

}

.right-subheader {
    width: fit-content;
    padding: 25px;
    max-width: 550px;
  
    text-align: center;
    font-size: 35px;
    font-weight: bold;
    font-family: 'Baloo 2';
    letter-spacing: 2px;
    color: #fff;
    
    border-radius: 2rem;
    box-shadow: 0 0 5px 10px rgba(50, 50, 50, 0.25);
    background: linear-gradient(-45deg, #282525, #1a191a, #2d2d2d, #2c2b2b);
    background-size: 400% 400%;
    
    animation: gradient 15s ease infinite;

}

@media screen and (max-width: 1300px) {
    .left-subheader, .right-subheader {
        max-width: 500px;
        font-size: 30px; 
    }
}

@media screen and (max-width: 1200px) {
    .left-subheader, .right-subheader {
        max-width: 425px;
        font-size: 25px; 
    }
}

@media screen and (max-width: 1023px) {
    .left-subheader, .right-subheader {
        max-width: 375px;
        font-size: 25px; 
    }
}

@media screen and (max-width: 950px) {
    .left-subheader, .right-subheader {
        max-width: 325px;
        font-size: 22.5px; 
    }
}


@media screen and (max-width: 820px) {
    .left-subheader, .right-subheader {
        max-width: 275px;
        font-size: 22.5px; 
    }
}

@media screen and (max-width: 725px) {
    .left-subheader, .right-subheader {
        max-width: 250px;
        font-size: 20px; 
    }
}


@media screen and (max-width: 640px) {

    .left-subheader, .right-subheader {
        max-width: 225px; 
        font-size: 17.5px;
    } 
}

@media screen and (max-width: 575px) {

    .subheaderFlex {
        flex-direction: column;
        align-items: center;
        
    }

    .left-subheader, .right-subheader {
        max-width: 425px; 
        font-size: 25px;
    } 
}

@media screen and (max-width: 510px) {

    .subheaderFlex {
        flex-direction: column;
        align-items: center;
        
    }

    .left-subheader, .right-subheader {
        max-width: 400px; 
        font-size: 25px;
    } 
}

@media screen and (max-width: 450px) {

    .subheaderFlex {
        flex-direction: column;
        align-items: center;
        
    }

    .left-subheader, .right-subheader {
        max-width: 325px; 
        font-size: 25px;
    } 
}

@media screen and (max-width: 375px) {

    .subheaderFlex {
        flex-direction: column;
        align-items: center;
        
    }

    .left-subheader, .right-subheader {
        max-width: 275px; 
        font-size: 22.5px;
    } 
}

@media screen and (max-width: 310px) {

    .subheaderFlex {
        flex-direction: column;
        align-items: center;
        
    }

    .left-subheader, .right-subheader {
        max-width: 225px; 
        font-size: 22.5px;
    } 
}



  
/*==============================================================*/

.help-line{
  display: flex;
  justify-content: center;
  margin-top: 75px;

  width: 100%;
  height: 5px;
  border-radius: 2rem;

  background-color: #2d2d2d;
  opacity: .5;
}

/*==============================================================*/

.help-card-container {
    width: 1175px;
    height: 200px;
    perspective: 1000px; 
     
}
  
.help-card {
    height: 100%;
    width: 100%;
    position: relative;
    transition: transform 1500ms;
    transform-style: preserve-3d;   
}
  
.help-card-container:hover > .help-card {
    cursor: pointer;
    transform: rotateX(180deg);
}
  
.help-front,
.help-back {
    height: 100%;
    width: 100%;
    border-radius: 2rem;
    box-shadow: 0 0 5px 10px rgba(50, 50, 50, 0.25);
    position: absolute; 
    backface-visibility: hidden;

    background: linear-gradient(-45deg, #376b9f, #ae71ae, #2f57cf, #cf63c4);
    background-size: 400% 400%;
	animation: gradient 15s ease infinite;
	
   
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
  
.help-front {
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1em;
  
    font-size: 75px;
    font-weight: bold;
    font-family: 'Baloo 2';
    letter-spacing: 2px;
    text-align: center;
    color: #fff
}
  
.help-back {
    
    background: linear-gradient(-45deg, #282525, #1a191a, #2d2d2d, #2c2b2b);
    background-size: 400% 400%;
  
    animation: gradient 15s ease infinite;
   
  
    transform: rotateX(180deg); 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 25px;
    gap: 1em;
  
    font-size: 22.5px;
    font-weight: bold;
    font-family: 'Baloo 2';
    text-align: center;
    letter-spacing: 2px;
    color: #fff
}

.help-card-image {
    align-self: center;

    width: 192px;
    height: 192px;

    opacity: 0.5;
}

@media screen and (min-width: 575px) {
    .help-card-image {
        display: none; 
    }
}

@media screen and (max-width: 375px) {
    .help-card-image {
        width: 150px;
        height: 150px;  
    }
}

@media screen and (max-width: 1300px) {

    .help-card-container {
        width: 1075px;
        height: 200px;
    }

    .help-front {
        font-size: 65px;
        padding: 15px;
    }

    .help-back {
        font-size: 20px;
        letter-spacing: 1px;
    }
}

@media screen and (max-width: 1200px) {

    .help-card-container {
        width: 925px;
        height: 200px;
    }

}

@media screen and (max-width: 1023px) {

    .help-card-container {
        width: 825px;
        height: 200px;
    }

    .help-front {
        font-size: 55px; 
    }

    
}

@media screen and (max-width: 950px) {

    .help-card-container {
        width: 725px;
        height: 200px;
    }

    .help-front {
        font-size: 50px; 
    }

    .help-back {
        font-size: 18px;
    }

}

@media screen and (max-width: 820px) {

    .help-card-container {
        width: 625px;
        height: 225px;
    }

    .help-front {
        font-size: 50px; 
    }

    .help-back {
        font-size: 18px;
    }

}

@media screen and (max-width: 725px) {

    .help-card-container {
        width: 575px;
        height: 225px;
    }

    .help-front {
        font-size: 45px; 
    }

    .help-back {
        font-size: 18px;
    }

}

@media screen and (max-width: 640px) {

    .help-card-container {
        width: 525px;
        height: 225px;
    }

    .help-front {
        font-size: 45px; 
    }

    .help-back {
        font-size: 17px;
    }

}



@media screen and (max-width: 575px) {

    .help-card-container {
        width: 350px;
        height: 550px;
    }

    .help-front {
        font-size: 45px;
        padding: 15px;
    }

    .help-back {
        transform: rotateY(180deg); 

        font-size: 20px;
        font-weight: bold;
        letter-spacing: 1px;
    }

    .help-card-container:hover > .help-card {
        transform: rotateY(180deg);
    }


}

@media screen and (max-width: 450px) {

    .help-card-container {
        width: 300px;
        height: 500px;
    }

    .help-front {
        font-size: 45px;
        padding: 15px;
    }

    .help-back {
        transform: rotateY(180deg); 

        font-size: 18px;
        font-weight: 300;
        letter-spacing: 1px;
    }

    .help-card-container:hover > .help-card {
        transform: rotateY(180deg);
    }

}

@media screen and (max-width: 375px) {

    .help-card-container {
        width: 275px;
        height: 500px;
    }

    .help-front {
        font-size: 40px;
        padding: 15px;
    }

    .help-back {
        transform: rotateY(180deg); 

        font-size: 17px;
        font-weight: 300;
        letter-spacing: 1px;
    }

    .help-card-container:hover > .help-card {
        transform: rotateY(180deg);
    }

}

@media screen and (max-width: 310px) {

    .help-card-container {
        width: 225px;
        height: 500px;
    }

    .help-front {
        font-size: 30px;
        padding: 15px;
    }

    .help-back {
        transform: rotateY(180deg); 

        font-size: 15px;
        font-weight: 300;
        letter-spacing: 1px;
    }

    .help-card-container:hover > .help-card {
        transform: rotateY(180deg);
    }


}