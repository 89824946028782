  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    /* .card {
      position: relative;
      width: 150px;
      height: 150px;
      background: grey;
      border-radius: 5px;
      box-shadow: 0px 0px 30px 10px white;
      opacity: 90%;
      transition: box-shadow 0.5s, opacity 0.5s;
      will-change: transform;
      cursor: grab;
      overflow: hidden;
      touch-action: none;
    } */
  } 

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    /* .card {
      position: relative;
      width: 250px;
      height: 250px;
      background: grey;
      border-radius: 2px;
      box-shadow: 0px 0px 15px 5px white;
      opacity: 90%;
      transition: box-shadow 0.5s, opacity 0.5s;
      will-change: transform;
      cursor: grab;
      overflow: hidden;
      touch-action: none;
    } */
  } 

  @media only screen and (min-width: 750px) {
    /* .card {
      position: relative;
      width: 250px;
      height: 250px;
      background: grey;
      border-radius: 2px;
      box-shadow: 0px 0px 15px 5px white;
      opacity: 90%;
      transition: box-shadow 0.5s, opacity 0.5s;
      will-change: transform;
      cursor: grab;
      overflow: hidden;
      touch-action: none;
    } */
  } 

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 1150px) {
    .card {
      position: relative;
      width: 350px;
      height: 350px;
      background: grey;
      border-radius: 5px;
      box-shadow: 0px 0px 30px 10px white;
      opacity: 90%;
      transition: box-shadow 0.5s, opacity 0.5s;
      will-change: transform;
      cursor: grab;
      overflow: hidden;
      touch-action: none;
    }
  }






.card:hover {
  box-shadow: 0px 30px 100px -10px #3a0ca3;
}

.card > div {
  will-change: transform;
  height: 100%;
  margin: 0vw 0;
}

.card > div > * {
  height: 100%;
  background-size: cover;
  background-position: center center;
  margin: 0vw 0;
}

.container {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
}


