

    



/*==============================================================*/

.title-image {
  width: 150px;
  height: 150px;
}

.treasury-image {
  width: 200px;
  height: 125px;

  padding-left: 10px;
  padding-right: 10px;
}


@media screen and (max-width: 1500px) {
  .title-image {
    width: 125px;
    height: 125px;
  }

}

@media screen and (max-width: 1200px) {
  .title-image {
    width: 100px;
    height: 100px;  
  }

}

@media screen and (max-width: 1023px) {
  .title-image {
    width: 75px;
    height: 75px;  
  }

}

@media screen and (max-width: 950px) {
  .title-image {
    display: none; 
  }

}

@media screen and (max-width: 1500px) {
  .treasury-image {
    width: 175px;
    height: 100px;

    padding-left: 7.5px;
    padding-right: 7.5px;
  }

}

@media screen and (max-width: 1200px) {
  .treasury-image {
    width: 150px;
    height: 100px; 
    
    padding-left: 5px;
    padding-right: 5px;
  }

}

@media screen and (max-width: 1023px) {
  .treasury-image {
    width: 115px;
    height: 80px; 
    
    padding-left: 2.5px;
    padding-right: 2.5px;
  }

}

@media screen and (max-width: 950px) {
  .treasury-image {
    display: none; 
  }

}


/*==============================================================*/

.card-container {
  width: 350px;
  height: 500px;
  perspective: 1000px; 
}

.card {
  height: 100%;
  width: 100%;
  position: relative;
  transition: transform 1500ms;
  transform-style: preserve-3d;  
}

.card-container:hover > .card {
  cursor: pointer;
  transform: rotateY(180deg);
}

.front,
.back {
  height: 100%;
  width: 100%;
  border-radius: 2rem;
  box-shadow: 0 0 5px 10px #32323240;
  position: absolute; 
  backface-visibility: hidden;
}

.front {

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.75em;

  
  background-image: url('../../assets/Lightning_Bursts_1_2286_2K_AdobeExpress_AdobeExpress_AdobeExpress.gif');

  font-size: 50px;
  font-weight: bold;
  font-family: 'Baloo 2';
  letter-spacing: 4px;
  text-align: center;
 
  color: #fff;
  
}

.back {
  
  background: linear-gradient(-45deg, #0f0e0e, #090809, #2d2d2d, #0b0b0b);
  background-size: 400% 400%;

  animation: gradient 15s ease infinite;
 

  transform: rotateY(180deg); 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
  gap: 1.75em;


  font-size: 35px;
  font-weight: bold;
  font-family: 'Baloo 2';
  text-align: center;
  color: #fff
}

.card-image {
  width: 200px;
  height: 200px;

  opacity: 0.5; 
}


@media screen and (max-width: 1500px) {
  .card-container {
    width: 300px;
    height: 450px;
  }

  .front {
    font-size: 50px; 
  }

  .back {
    font-size: 25px;
  }

  .card-image {
    width: 150px;
    height: 150px;
  }
}

@media screen and (max-width: 1280px) {
  .card-container {
    width: 275px;
    height: 425px;
  }

  .front {
    font-size: 35px; 
  }

  .back {
    font-size: 25px;
  }

  .card-image {
    width: 125px;
    height: 125px;
  }
}

@media screen and (max-width: 1150px) {
  .card-container {
    width: 250px;
    height: 400px;
  }

  .front {
    font-size: 35px; 
  }

  .back {
    font-size: 25px;
  }

  .card-image {
    width: 100px;
    height: 100px;
  }
}

@media screen and (max-width: 950px) {
  .card-container {
    width: 200px;
    height: 350px;
  }

  .front {
    font-size: 30px; 
  }

  .back {
    font-size: 22.5px;
  }

  .card-image {
    width: 100px;
    height: 100px;
  }
}

@media screen and (max-width: 750px) {
  .card-container {
    width: 175px;
    height: 350px;
  }

  .front {
    font-size: 25px; 
  }

  .back {
    font-size: 20px;
  }

  .card-image {
    width: 100px;
    height: 100px;
  }
}

@media screen and (max-width: 675px) {
  .card-container {
    width: 275px;
    height: 375px;
  }

  .front {
    font-size: 40px; 
  }

  .back {
    font-size: 25px;
  }

  .card-image {
    width: 125px;
    height: 125px;
  }
}

@media screen and (max-width: 380px) {
  .card-container {
    width: 225px;
    height: 325px;
  }

  .front {
    font-size: 30px; 
  }

  .back {
    font-size: 22.5px;
  }

  .card-image {
    width: 100px;
    height: 100px;
  }
}


@media screen and (max-width: 280px) {
  .card-container {
    width: 200px;
    height: 300px;
  }

  .front {
    font-size: 25px; 
  }

  .back {
    font-size: 15px;
  }

  .card-image {
    width: 75px;
    height: 75px;
  }
}




/*==============================================================*/

.dao-line{
  display: flex;
  justify-content: center;
  margin-top: 75px;

  width: 100%;
  height: 5px;
  border-radius: 2rem;

  background-color: #2d2d2d;
  opacity: .5;
}


@media screen and (max-width: 40em) {
  .dao-line {
    width: 100%;
    font-size: 20px;
    margin-top: 50px;
  }

}
  
/*==============================================================*/



.daoBtn {
  width: 400px;
  height: 100px;
  border-radius: 2rem;
  transition: ease-out 0.5s;
  outline: none;
  border: none;
  cursor: pointer;


  font-weight: bold;
  font-size: 25px;
  font-family: 'Baloo 2';
  letter-spacing: 2px;
  text-align: center;

  background: linear-gradient(-45deg, #37829f, #ae71ae, #2f57cf, #cf63c4);
  background-size: 400% 400%;
	animation: gradient 15s ease infinite;

  color: #fff;

}

.daoBtn:hover {
  background-color: #2d2d2d;
  box-shadow: inset 400px 0 0 0 #2d2d2d;
}

@media screen and (max-width: 1280px) {
  .daoBtn {
    width: 300px;
    font-size: 22.5px;
  }

  .daoBtn:hover {
    box-shadow: inset 750px 0 0 0 #2d2d2d;
  }
}

@media screen and (max-width: 950px) {
  .daoBtn {
    width: 675px;
    font-size: 25px;
  }

  .daoBtn:hover {
    box-shadow: none;
  }
}

@media screen and (max-width: 820px) {
  .daoBtn {
    width: 600px;
    font-size: 25px;
  }

  .daoBtn:hover {
    box-shadow: none;
  }
}

@media screen and (max-width: 768px) {
  .daoBtn {
    width: 525px;
    font-size: 25px;
  }

  .daoBtn:hover {
    box-shadow: none;
  }
}

@media screen and (max-width: 640px) {
  .daoBtn {
    width: 450px;
    font-size: 25px;
  }

  .daoBtn:hover {
    box-shadow: none;
  }
}

@media screen and (max-width: 565px) {
  .daoBtn {
    width: 375px;
    font-size: 22.5px;
  }

  .daoBtn:hover {
    box-shadow: none;
  }

}

@media screen and (max-width: 490px) {
  .daoBtn {
    width: 300px;
    font-size: 22.5px;
  }

  .daoBtn:hover {
    box-shadow: none;
  }

}

@media screen and (max-width: 350px) {
  .daoBtn {
    width: 200px;
    font-size: 15px;
  }

  .daoBtn:hover {
    box-shadow: none;
  }

}



/*==============================================================*/

.nft-container {
  display: flex;
  flex-wrap: wrap; 
  flex-direction: row;


  justify-content: space-evenly;
  align-items: center;


  max-width: 1500px;
  margin: 1rem auto;
  margin-top: 75px;

}


